import dojo_string = require("dojo/string");
import Base = require("Everlaw/Base");

import Bates = require("Everlaw/Bates");
import Arr = require("Everlaw/Core/Arr");
import C = require("Everlaw/Constants");
import Is = require("Everlaw/Core/Is");
import DateUtil = require("Everlaw/DateUtil");
import Dom = require("Everlaw/Dom");
import Duration = require("Everlaw/Duration");
import E = require("Everlaw/Entities");
import Project = require("Everlaw/Project");
import ProjectDateUtil = require("Everlaw/ProjectDateUtil");
import SelfImport = require("Everlaw/Type");
import UI = require("Everlaw/UI");
import ConstrainedBox = require("Everlaw/UI/ConstrainedBox");
import UI_DateBox = require("Everlaw/UI/DateBox");
import Icon = require("Everlaw/UI/Icon");
import UI_LocalDateBox = require("Everlaw/UI/LocalDateBox");
import NumberBox = require("Everlaw/UI/NumberBox");
import RangeWidget = require("Everlaw/UI/RangeWidget");
import SingleSelect = require("Everlaw/UI/SingleSelect");
import UI_Validated = require("Everlaw/UI/Validated");
import Widget = require("Everlaw/UI/Widget");
import Util = require("Everlaw/Util");
import * as Bugsnag from "Everlaw/Bugsnag";
import { BatesSearch } from "Everlaw/Bates";
import type { AddressTermJson } from "Everlaw/Datavis/Visualization";
import { comparePrecision, P, Precision, PrecisionName } from "Everlaw/DateTimePrecision";
import {
    getProjectMomentJSDateFormat,
    getProjectDateDisplayFormat,
    getSearchTimeDisplayFormat,
    getProjectMomentJSTimeFormat,
} from "Everlaw/ProjectDateUtil";
import { NO_VALUE } from "Everlaw/SearchConstants";
import { MetadataTerm, TypedValueTerm } from "Everlaw/SearchTerms";
import BaseSingleSelect from "Everlaw/UI/BaseSingleSelect";
import * as moment from "moment-timezone";

/**
 * Encapsulates a value's representation, how to display it, how a user inputs
 * it, and how to convert it for transmission to and from the server.
 * There are "primitive" types for Null, Number, Text, and DateTime that
 * correspond to their Java equivalents.
 * There are also richer types for Base.Objects in general (present in this file)
 * and more specific implementations in the corresponding Base.Object files.
 * By convention, they are defined in OBJECTNAME.Type (e.g. Rating.js defines
 * Rating.Type).
 * Lastly, there are parameterized types that allow for a more expressive type
 * language.
 * Types here that are not parameterized are singletons, and should be referred
 * to directly as Type.TheType. Types that are parameterized on another type,
 * e.g. Type.List, must be instantiated, for example, new Type.List(Type.Text).
 */
export class Type {
    isSortable = false;
    isVisualizable = false;
    constructor(public name: string) {}

    displayName() {
        return this.name;
    }

    // Since non-parameterized types are singletons, two types are equal only if
    // they are the same object. If you are implementing a parameterized type,
    // you must redefine equals to provide your own notion of equality.
    equals(other: Type) {
        return this === other;
    }

    /**
     * Returns a string describing the value. Returning null indicates that the provided value is
     * illegal for this type.
     */
    displayValue(value: any): string {
        return value;
    }

    // Converts rich representation of this value to one suitable to pass to the
    // server (e.g. a User object to its id).
    toJsonValue(value: any) {
        return value;
    }

    fromJsonValue(value: any) {
        return value;
    }

    // Returns true if the value is valid for this type (e.g. a number for
    // Type.Number).
    isValidValue(value: any): boolean {
        return value;
    }

    requiresFormat = false;

    compareRawValue(a: any, b: any): boolean {
        return a === b;
    }
}

class NullType extends Type implements EditableType {
    override isSortable = false;
    // Null would be confused with an illegal value, so we return an empty string instead.
    override displayValue(value: void) {
        return "";
    }
    override isValidValue(value: void) {
        return value === null;
    }
    constructValueWidget() {
        return new Widget.NullWidget();
    }
}

class BooleanType extends Type {
    override isSortable = true;
    override isValidValue(value: boolean) {
        return Is.boolean(value);
    }
}

export interface EditableType extends Type {
    // Returns a UI widget by which the user can edit a value of this type. Types implementing
    // this method may accept optional parameters appropriate to the widget they create.
    constructValueWidget(): Widget.WithSettableValue;
}

// The MEDIUMTEXT database field for the eql can take 16,777,215 bytes which could mean
// the same number of characters but not necessarily if any of the characters are more
// than one byte. This won't restrict all inputs that are invalid for being too large but will stop
// inputs that are definitely too large.
export const MEDIUMTEXT_LIMIT = 16777215;

export interface SearchWithExactness<T> {
    // Whether the returned search value is an exact search (plain value) or not.
    exact: boolean;
    // The search value (or plain value) to use to search on this value.
    searchValue: T;
}

/**
 * Any type used to represent metadata must implement this interface.
 */
export abstract class FieldType extends Type implements EditableType {
    abstract constructValueWidget(): Widget.WithSettableValue;

    termWidget(term: TypedValueTerm): Widget.WithSettableValue {
        return this.constructValueWidget();
    }

    termRangeWidget(term: TypedValueTerm): Widget.WithSettableValue {
        return this.searchType().constructValueWidget();
    }

    metadataTableWidget(): Widget.WithSettableValue {
        return this.constructValueWidget();
    }

    /**
     * Returns a type for the values used to search a field of this type.
     */
    abstract searchType(): EditableType;

    /**
     * Determine how to generate a search appropriate for exploring docs with a specific value.
     * Sometimes this will involve an "ExactMetadata" property, but other times a regular "Metadata"
     * property is more appropriate.
     *
     * Returns a `SearchWithExactness`, or null if a search on the given value is not supported.
     *
     * As currently used, the type of `value` can be either data passed directly from the backend,
     * or the rich representation of that object returned by `Type#fromJsonValue`.
     *
     * TODO: Refactor here to get more clear typing. This would involve replacing most of the `any`
     *  types taken in and returned by the functions in `Type`.
     */
    searchFromExactValue(value: unknown, type?: FieldType): SearchWithExactness<unknown> | null {
        return { exact: true, searchValue: value };
    }

    /**
     * Must match `viewableAs` in Type.java.
     */
    viewableAs(other: FieldType): boolean {
        return this.equals(other);
    }

    /**
     * Whether to append "(Exact)" to the text summary of the display value in Eql Builders.
     */
    abstract appendExactString: boolean;

    /**
     * Whether to show the "exact" checkbox for the widget.
     */
    showExactness = true;

    /**
     * Whether any arbitrary string parses as a valid value.
     */
    alwaysParses = false;

    /**
     * Can a single doc have multiple terms in a field?
     */
    multiValued = false;

    /**
     * Whether to display invalid values in the term summary. Should be `true` when `displayValue`
     * returns error text on invalid values that can be displayed to users.
     */
    displayInvalidValues = false;

    /**
     * Note: Not 100% sure `viewableAs(TEXT)` will always correspond to left-alignment, but for now
     * it does.
     */
    isRightAligned() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return !this.viewableAs(TEXT);
    }

    termFromJson(obj: any): AutocompleteTerm {
        return obj;
    }

    editValue(value: any): any {
        return value;
    }

    /**
     * For most metadata types, the "raw value" is the same as the "value".
     * Compound types such as AddressListType need to override this.
     */
    rawValue(value: any): string {
        return value;
    }

    /**
     * Subclasses can override to have different rules for validity of user edited value.
     */
    isValidEditValue(value: any): boolean {
        return this.isValidValue(value);
    }
}

export abstract class ValidatedFieldType extends FieldType {
    forms: UI_Validated.Validated[];
    getForms(): UI_Validated.Validated[] {
        return this.forms;
    }
}

// Text metadata types can be a string or an array of strings, so we need to handle both cases

interface TruncatedArray {
    truncated: boolean;
    values: string | string[];
}

export class TextType extends ValidatedFieldType {
    constructor(
        name: string,
        private textBoxParams?: UI.WidgetWithTextBoxParams,
    ) {
        super(name);
    }
    override alwaysParses = true;
    static emptyValue = "";
    static displayValue(value: string | string[] | TruncatedArray) {
        return TextType.valuesArray(value, true).join("; ");
    }
    static valuesArray(
        value: string | string[] | TruncatedArray,
        includeTruncatedWarning: boolean,
    ) {
        let fullVal: TruncatedArray;
        if (Is.string(value) || Is.array(value)) {
            fullVal = { values: <string | string[]>value, truncated: false };
        } else {
            fullVal = <TruncatedArray>value;
        }
        let base = Arr.wrap(fullVal.values);
        if (fullVal.truncated && includeTruncatedWarning) {
            base = base.slice();
            base.push("and more values...");
        }
        return base;
    }
    override isSortable = true;
    override isVisualizable = true;
    appendExactString = true;
    searchType() {
        return this;
    }

    override isValidValue(value: string | string[] | TruncatedArray) {
        return TextType.valuesArray(value, false).every((v) => {
            return Is.string(v) && !!dojo_string.trim(v);
        });
    }

    override displayValue(value: string | string[] | TruncatedArray) {
        return TextType.displayValue(value);
    }

    constructValueWidget(params?: UI_Validated.TextParams) {
        const tb = new TextValueTextBox(params);
        tb.onSubmit = tb.blur;
        this.forms = [tb];
        return tb;
    }

    override termRangeWidget(term: TypedValueTerm): Widget.WithSettableValue {
        return null;
    }

    override termWidget(term: TypedValueTerm): Widget.WithSettableValue {
        return term.createTextWidget(
            Object.assign(
                {
                    placeholderText: term.getTextWidgetPlaceholder(),
                    textBoxAriaLabel: term.getTextWidgetAriaLabel(),
                    alwaysShowNewOption: false,
                    noColorBorder: false,
                    stayFilteredOnSelect: false,
                    forceDirection: false,
                },
                this.textBoxParams,
            ),
        );
    }

    override searchFromExactValue(value: string, type?: FieldType): SearchWithExactness<string> {
        return { exact: true, searchValue: value };
    }
}

interface Address {
    name?: string;
    email?: string;
}

interface AddressListValue {
    raw: string;
    canonical: string;
    addresses: Address[];
}

/**
 * Max number of name/email terms we allow in an exclusive ("and nothing else") address list query.
 * The reason for the limitation is the combined field Lucene regex (see {@code AddressListSearch}
 * on the server) that gets unwieldy (and can break) if composed of too many values.
 *
 * 1000 terms are possible with the 10 buckets, and the new encoded terms (8 Base64 chars).
 *
 * This should match the identically-named constant in SearchTypeAdapter.java
 */
export const MAX_EXCLUSIVE_EMAIL_TERMS = 1000;

/**
 * Max number of domain terms we allow in an exclusive ("and nothing else") address list query.
 * The reason for the limitation is the combined field Lucene regex (see {@code AddressListSearch}
 * on the server) that gets unwieldy (and can break) if composed of too many values.
 *
 * This is smaller than {@link MAX_EXCLUSIVE_EMAIL_TERMS} because the exclusive domain
 * index has only one bucket (field) as opposed to 10 for exclusive email index,
 * and because the terms are not encoded (hard due to subdomains).
 *
 * This should match the identically-named constant in SearchTypeAdapter.java
 */
export const MAX_EXCLUSIVE_DOMAIN_TERMS = 25;

/**
 * conservatively estimate 5 terms (4 emails and one name) for names (the average on some tests
 * we ran is less than 2 emails per name)
 *
 * This should match the identically-named constant in SearchTypeAdapter.java
 */
export const TERMS_PER_NAME_ESTIMATE = 5;

export enum ComboType {
    ANY = "ANY",
    ALL = "ALL",
    NOT_ANY = "NOT_ANY",
}

export enum AddressTermKind {
    EMAIL = "EMAIL",
    NAME = "NAME",
    DOMAIN = "DOMAIN",
    TEXT = "TEXT",
    NO_VALUE = "NO_VALUE",
}

export class AddressListType extends FieldType {
    override isSortable = true;
    override isVisualizable = true;
    appendExactString = false;
    override showExactness = false;

    hideComboRadio = false;
    override alwaysParses = true;
    override multiValued = true;

    override displayValue(value: AddressListValue | string): string {
        // TODO: remove the string case once it's no longer possible.
        // (Perhaps after the address list datavis feature is merged.)
        return typeof value === "string" ? value : value.canonical;
    }

    override editValue(value: AddressListValue): string {
        return value.raw;
    }

    override rawValue(value: AddressListValue | string): string {
        // TODO: remove the string case once it's no longer possible.
        // (Perhaps after the address list datavis feature is merged.)
        return typeof value === "string" ? value : value.raw;
    }

    override termWidget(term: TypedValueTerm): Widget.WithSettableValue {
        return term.createAddressSearchWidget();
    }

    override termRangeWidget(term: TypedValueTerm) {
        return null;
    }

    override metadataTableWidget(): Widget.WithSettableValue {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return TEXT.metadataTableWidget();
    }

    searchType() {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return ADDRESS_LIST_SEARCH;
    }

    constructValueWidget() {
        return null;
    }

    /**
     * Given a value (from the metadata table of the doc), it creates an "all exclusive" search for
     * all the addresses in the value, preferring email over name (for more accuracy and less heavy
     * query in some cases). Note that this is overridden in AddressFromType since there's no
     * "exclusive" for FROM (it's naturally exclusive) and ALL makes no sense so it's "ANY".
     *
     * We also switch to non-exclusive if the estimated number of terms (we have to estimate in
     * case of names) is high (see {@link MAX_EXCLUSIVE_EMAIL_TERMS}.)
     */
    override searchFromExactValue(
        value: AddressListValue,
        type?: FieldType,
    ): SearchWithExactness<AddressListSearch> | SearchWithExactness<string> {
        // SPECIAL CASE: Called from an alias field (which has to be of type TEXT.)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (type === TEXT) {
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return TEXT.searchFromExactValue(value.raw);
        }

        const terms = this.exactTerms(value.addresses);

        const searchValue: AddressListSearch = {
            terms,
            comboType: ComboType.ALL,
            exclusive: this.estimatedTermsCount(terms) <= MAX_EXCLUSIVE_EMAIL_TERMS,
        };

        return {
            exact: false,
            searchValue,
        };
    }

    /**
     * prefer email over name if email available (see above)
     */
    private exactTerms(addresses: Address[]) {
        return addresses.map((addr) =>
            addr.email
                ? new AddressTerm(addr.email, AddressTermKind.EMAIL)
                : new AddressTerm(addr.name, AddressTermKind.NAME),
        );
    }

    /**
     * We know an email is just one term, and count an estimate of expanded terms per name.
     */
    private estimatedTermsCount(terms: AddressTerm[]) {
        return terms
            .map((t) => (t.kind == AddressTermKind.NAME ? TERMS_PER_NAME_ESTIMATE : 1))
            .reduce((sum, n) => sum + n, 0);
    }

    override termFromJson(obj: any): AddressTerm {
        return AddressTerm.fromJson(obj);
    }

    /**
     * We always get a string from user edited field validation.
     */
    override isValidEditValue(value: string): boolean {
        if (!value) {
            return false;
        }
        // Make sure it has at least one alpha char, otherwise if the user enters only
        // separator chars (comma, semicolon, etc) the addresses might be empty.
        return /[a-z]/i.test(value);
    }

    override isValidValue(value: AddressListValue | string): boolean {
        // TODO: remove the string case once it's no longer possible.
        // (Perhaps after the address list datavis feature is merged.)
        if (typeof value === "string") {
            return true;
        }
        // just a simple sanity check for AddressListValue. If it has these properties, the
        // addresses property would always be legal too (unless it's a bug.)
        return typeof value.raw === "string" && typeof value.canonical === "string";
    }

    override viewableAs(other: FieldType): boolean {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return other === ADDRESS_LIST || other === TEXT;
    }
}

class AddressFromType extends AddressListType {
    /**
     * Override since the From field has no "exclusive" (it's naturally exclusive as there's only
     * one value, thus we don't index the combined field). Setting to "ANY" since it is what From
     * is set to in the search widget (and can't be modified, as "ALL" will never return anything
     * for more than one value.)
     */
    override searchFromExactValue(
        value: AddressListValue,
        type?: FieldType,
    ): SearchWithExactness<AddressListSearch> | SearchWithExactness<string> {
        const search = super.searchFromExactValue(value, type);

        // SPECIAL CASE: Called from an alias field (which has to be of type TEXT.)
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        if (type === TEXT) {
            return search;
        }

        const searchValue = search.searchValue as AddressListSearch;
        searchValue.comboType = ComboType.ANY;
        searchValue.exclusive = false;
        return search;
    }

    override viewableAs(other: FieldType): boolean {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return other === ADDRESS_FROM || other === ADDRESS_LIST || other === TEXT;
    }
}

class PathTextType extends TextType {
    static override displayValue(value: string | string[]) {
        return Util.pathToString(Arr.wrap(value));
    }
    override isValidValue(value: string | string[]) {
        return Arr.wrap(value).every((v) => {
            return Is.string(v); // PathText permits empty strings between delimiters
        });
    }
}

class TextValueTextBox extends UI_Validated.Text implements Widget.WithSettableValue {
    constructor(params?: UI_Validated.TextParams) {
        if (params) {
            Object.assign(params, {
                name: "value",
                max: MEDIUMTEXT_LIMIT,
            });
        } else {
            params = {
                name: "value",
                max: MEDIUMTEXT_LIMIT,
            };
        }
        super(params);
    }
    override setValue(value: string | string[]) {
        super.setValue(TextType.displayValue(value));
    }
    override setWidth(value: string) {
        Dom.style(this.node, "width", value);
    }
}

export type NumberRange = UI.Range<number>;

export class NumberType extends ValidatedFieldType {
    override isSortable = true;
    override isVisualizable = true;
    appendExactString = false;
    constructor(
        name: string,
        public min = C.INTMIN,
        public max = C.INTMAX,
    ) {
        super(name);
    }
    searchType() {
        return new NumberRangeType(this);
    }
    override isValidValue(value: number) {
        return (
            Is.number(value)
            && (this.min == null || value >= this.min)
            && (this.max == null || value <= this.max)
        );
    }
    constructValueWidget(params?: NumberBox.Params) {
        const widget = new NumberBox(
            Object.assign(
                {
                    min: this.min,
                    max: this.max,
                },
                params,
            ),
        );
        this.forms = [widget.getForm()];
        return widget;
    }
}

export class NumberRangeType extends Type implements EditableType {
    constructor(public valueType: NumberType) {
        super("NumberRange");
    }
    override isValidValue(value: NumberRange) {
        return (
            Is.object(value)
            // at least one of begin or end is present
            && (value.begin != null || value.end != null)
            // begin is valid or the null half
            && (this.valueType.isValidValue(value.begin) || value.begin == null)
            // end is valid or the null half
            && (this.valueType.isValidValue(value.end) || value.end == null)
        );
    }
    constructValueWidget(params?: RangeWidget.Params<NumberBox.Params>) {
        const widget = new NumberRangeWidget(
            Object.assign(
                {
                    defaultBoxParams: {
                        min: this.valueType.min,
                        max: this.valueType.max,
                        boxFormat: {
                            invalidMessage: "Invalid range, use e.g. 1 to 5",
                            validator: (value) => {
                                return this.isValidValue(widget.getValue());
                            },
                        },
                    },
                },
                params,
            ),
        );
        return widget;
    }
    override displayValue(
        value: NumberRange,
        isFileSize = false,
        places = 3,
        noPlacesOnZero = false,
    ) {
        const begin =
            isFileSize && value.begin
                ? Util.displayFileSize(
                      value.begin,
                      false,
                      parseInt(String(value.begin)) === 0 && noPlacesOnZero ? 0 : places,
                  )
                : value.begin;
        const end =
            isFileSize && value.end ? Util.displayFileSize(value.end, false, places) : value.end;
        if (value.begin == null) {
            return "≤ " + end;
        } else if (value.end == null) {
            return "≥ " + begin;
        } else if (value.begin === value.end) {
            return begin + "";
        }
        return begin + " – " + end;
    }
}

export class NumberRangeWidget extends RangeWidget<number, NumberBox, NumberBox.Params> {
    static defaultWidth = "110px";
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    override type = NUMBER;
    buildWidget(params: NumberBox.Params) {
        return new NumberBox(params);
    }
    constructor(params: RangeWidget.Params<NumberBox.Params> = {}) {
        super(Object.assign({ width: NumberRangeWidget.defaultWidth }, params));
    }
    // This needs to be overridden because the default setRange in RangeWidget will ignore begin/end
    // values of 0.
    override setRange(begin: number, end: number) {
        if (begin !== null) {
            this.begin.setValue(begin, true);
        }
        if (end !== null) {
            this.end.setValue(end, true);
        }
    }
}

export interface DateTime {
    lower: number; // Milliseconds since epoch (or midnight if time-only precision)
    precision: Precision;
    timezone?: string;
    format?: string;
}

export function compareDateTime(aVal: DateTime, bVal: DateTime, ascending: boolean) {
    if (aVal === null) {
        return bVal === null ? 0 : 1;
    } else if (bVal === null) {
        return -1;
    }
    if (aVal.lower === bVal.lower) {
        return comparePrecision(aVal.precision, bVal.precision);
    }
    return (ascending ? 1 : -1) * (aVal.lower - bVal.lower);
}

// Notice it extends DateTime, not DateTimeSearch as might be expected
export interface NullDateSearch extends DateTime {
    precision: -1; // Precision.noValue
}

export function isNullDateSearch(value: any): value is NullDateSearch {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return DATE_TIME.isValidValue(value) && value.precision === Precision.noValue;
}

export function dateOnly(lower: number) {
    return { lower, precision: Precision.dateOnly };
}

export function dateTime(lower: number, timezone?: string) {
    return { lower, precision: Precision.dateTime, timezone: timezone };
}

export interface DateTimeSearch {
    begin?: DateTime;
    end?: DateTime;
    type: PrecisionName;
    /**
     * If timezone is absent, UTC should be assumed. (Use DateTimeSearch.getTimezone to do this
     * defaulting)
     */
    timezone?: DateUtil.TimezoneNO;
}

export class DateTimeType extends ValidatedFieldType {
    override isSortable = true;
    override isVisualizable = true;
    appendExactString = false;
    // This corresponds to "No date/time" selection and mirrors Value.NULL_VALUE in DateTime.java
    static readonly NULL_VALUE: NullDateSearch = { lower: 0, precision: -1 };

    override displayName() {
        // TODO: replace with some more friendly display like "Date/Time"
        return this.name;
    }

    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    searchType() {
        return DATE_TIME_SEARCH;
    }
    override isValidValue(value: any): value is DateTime {
        // See DateTime.java for valid precision range
        return (
            Is.object(value)
            && Is.number(value.lower)
            && Is.number(value.precision)
            && Precision.noValue <= value.precision
            && value.precision <= Precision.dateTime
        );
    }
    override searchFromExactValue(value: DateTime): SearchWithExactness<DateTimeSearch> | null {
        // We only allow searches on dates.
        if (!this.hasDate(value)) {
            return null;
        }
        // Our search starts at the beginning of the day represented by `value`. Note that we include
        // timezone in the precision of exact searches because our date widget can't preserve the lack
        // of a timezone anyways.
        const firstDayStart = {
            lower: moment(value.lower)
                .tz(Project.CURRENT.timezoneId)
                .startOf("day")
                .tz("UTC")
                .valueOf(),
            precision: Precision.dateOnly,
        };
        if (this.hasPrecision(value, P.DAY)) {
            return {
                exact: false,
                searchValue: {
                    // Since we're searching with dateOnly precision, we can use a "begin-to-begin"
                    // search. The result is a 24-hour window starting at `begin`.
                    begin: firstDayStart,
                    end: firstDayStart,
                    type: PrecisionName.dateOnly,
                    timezone: Project.CURRENT.timezoneId as DateUtil.TimezoneNO,
                },
            };
        } else {
            // If we don't have a date, we either have a month or a year. Create a search for that full
            // month (or year).
            const m = moment(firstDayStart.lower).tz(Project.CURRENT.timezoneId);
            if (this.hasPrecision(value, P.MONTH)) {
                // N.B. If this overflows past December, moment correctly gives January of the next year
                m.month(m.month() + 1);
            } else {
                m.year(m.year() + 1);
            }
            m.subtract(1, "day");
            const lastDayEnd = { lower: m.valueOf(), precision: Precision.dateOnly };
            return {
                exact: false,
                searchValue: {
                    begin: firstDayStart,
                    end: lastDayEnd,
                    type: PrecisionName.dateOnly,
                    timezone: Project.CURRENT.timezoneId as DateUtil.TimezoneNO,
                },
            };
        }
    }
    constructValueWidget(params?: UI_DateBox.Params) {
        const dateBox = new UI_DateBox(params);
        this.forms = [dateBox.input];
        return dateBox;
    }
    override termRangeWidget(term: TypedValueTerm): Widget.WithSettableValue {
        return null;
    }
    override termWidget(term: TypedValueTerm): Widget.WithSettableValue {
        return term.createDateWidget();
    }
    override displayValue(value: DateTime, short = false) {
        if (value.precision === Precision.noValue) {
            return "(No value)";
        } else if (this.hasDate(value)) {
            if (this.hasPrecision(value, P.DAY)) {
                // Note: some metadata is saved as midnight UTC with ZONE precision, but doesn't
                // actually include timezone information. We use the presence of timezone here
                // to assume project timezone, but that's not entirely correct and should probably
                // be fixed to actually use the saved timezone.
                // If you are going to change the timezone used to display datetime values on the
                // frontend, make sure to change the timezone used when exporting datetime values
                // on the backend (see DateTime.java) so that the exported value matches the value
                // displayed in the platform.
                if (this.hasPrecision(value, P.ZONE) && !!value.timezone) {
                    if (this.hasTime(value)) {
                        if (ProjectDateUtil.getShowTimezone()) {
                            return ProjectDateUtil.displayDateTimeProjectFormatWithTimezone(
                                value.lower,
                            );
                        } else {
                            return ProjectDateUtil.displayDateTimeProjectFormat(value.lower);
                        }
                    }
                    return ProjectDateUtil.displayFullDateProjectFormat(value.lower);
                } else {
                    if (this.hasTime(value)) {
                        return short
                            ? DateUtil.shortDateTimeUTCWithFormats(
                                  value.lower,
                                  getProjectDateDisplayFormat(),
                                  getProjectMomentJSTimeFormat(),
                              )
                            : ProjectDateUtil.displayDateTimeAsUtcUnknownTimezone(value.lower);
                    } else {
                        return DateUtil.displayFullDateWithFormat(
                            DateUtil.asDate(value.lower),
                            getProjectDateDisplayFormat(),
                            short,
                        );
                    }
                }
            } else if (this.hasPrecision(value, P.MONTH)) {
                if (getProjectDateDisplayFormat() === DateUtil.DateDisplayFormat.YMD) {
                    return short
                        ? DateUtil.displayShortYearMonthUTC(value.lower)
                        : DateUtil.displayYearMonthUTC(value.lower);
                } else {
                    return short
                        ? DateUtil.displayShortMonthYearUTC(value.lower)
                        : DateUtil.displayMonthYearUTC(value.lower);
                }
            } else {
                return DateUtil.displayYearUTC(value.lower);
            }
        }
        return ProjectDateUtil.displayTime(value.lower);
    }
    hasDate(value: DateTime) {
        return this.hasPrecision(value, P.YEAR);
    }
    hasTime(value: DateTime) {
        return this.hasPrecision(value, P.HOUR);
    }
    hasPrecision(value: DateTime, precision: P) {
        return value.precision & precision;
    }
    // Date time doesn't have a useful text raw value at this point, so we just return the
    // display value to maintain compatibility with callers of rawValue
    override rawValue(value: any): string {
        return this.displayValue(value);
    }

    override showExactness = false;
    override requiresFormat = true;
    override compareRawValue(a: any, b: any): boolean {
        return a.lower === b.lower && a.precision === b.precision;
    }
}

class DateTimeSearchType extends Type implements EditableType {
    /**
     * This method is duplicated on the backend: DateTime.RangeSerach.isValid()
     */
    override isValidValue(searchValue: any): searchValue is DateTimeSearch {
        if (!Is.object(searchValue)) {
            return false;
        }
        if (!Is.string(searchValue.type)) {
            return false;
        }
        const b = searchValue.begin;
        const e = searchValue.end;
        if (searchValue.type !== PrecisionName.timeOnly) {
            if (b == null && e == null) {
                return false;
            }
            if (b == null || e == null) {
                // Exactly one of the two is null.
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                return DATE_TIME.isValidValue(b || e);
            }
            // Both are non-null.
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return DATE_TIME.isValidValue(b) && DATE_TIME.isValidValue(e) && b.lower <= e.lower;
        } else {
            // Time-only searches are a bit weird at first glance: Compared to date-time and
            // date-only, there are two differences: 1) the start time need not be before the end
            // time, and 2) both start and time must be provided; open intervals are not allowed.
            //
            // Why? Respectively, since time of day is cyclical, 1) e.g. 9pm-3am makes as much sense
            // as 3am-9pm, and 2) open intervals don't make sense. We could assume midnight for open
            // intervals if we wanted to, though.
            if (b == null || e == null) {
                return false;
            }
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            return DATE_TIME.isValidValue(b) && DATE_TIME.isValidValue(e);
        }
    }
    constructValueWidget(params?: RangeWidget.Params<ConstrainedBox.Params>): never {
        throw Error("Use DateSearchWidget instead (not a drop-in replacement).");
    }
    /**
     * Assumes UTC. For other timezones, use displayValueWithTimezone instead.
     *
     * Be careful editing this method: It's duplicated on the backend as
     * DateTime.RangeSearch.display(String timezone).
     */
    override displayValue(value: DateTimeSearch) {
        const timezone = this.getTimezone(value);
        const tzSuffix =
            " "
            + DateUtil.displayTimezone(timezone, (value.begin || value.end).lower)
            + " (Project Timezone)";
        if (value.type === PrecisionName.dateOnly || value.type === PrecisionName.dateAndTime) {
            const tz = timezone as DateUtil.TimezoneN;
            const displayer = this.getDisplayer(tz, value.type);
            if (value.begin == null) {
                return "on or before " + displayer(value.end.lower) + tzSuffix;
            } else if (value.end == null) {
                return "on or after " + displayer(value.begin.lower) + tzSuffix;
            } else if (value.begin.lower === value.end.lower) {
                if (value.type === PrecisionName.dateOnly) {
                    return "on " + displayer(value.begin.lower) + tzSuffix;
                } else {
                    return (
                        DateUtil.displayDateTimeAsZoneWithWords(value.begin.lower, tz) + tzSuffix
                    );
                }
            }
            return (
                "from "
                + displayer(value.begin.lower)
                + " to "
                + displayer(value.end.lower)
                + tzSuffix
            );
        } else if (value.type === PrecisionName.timeOnly) {
            const tz = timezone as DateUtil.TimezoneO;
            const timeDisplayFormat = getSearchTimeDisplayFormat();
            if (value.begin.lower !== value.end.lower) {
                return (
                    "from "
                    + DateUtil.displayDateTimeAsZoneWithFormat(
                        value.begin.lower,
                        tz,
                        timeDisplayFormat,
                    )
                    + " to "
                    + DateUtil.displayDateTimeAsZoneWithFormat(
                        value.end.lower,
                        tz,
                        timeDisplayFormat,
                    )
                    + tzSuffix
                );
            } else {
                return (
                    "at "
                    + DateUtil.displayDateTimeAsZoneWithFormat(
                        value.begin.lower,
                        tz,
                        timeDisplayFormat,
                    )
                    + tzSuffix
                );
            }
        }
    }
    private getDisplayer(tz: DateUtil.TimezoneN, valueType: PrecisionName) {
        const dateDisplayFormat = getProjectMomentJSDateFormat();
        if (valueType === PrecisionName.dateOnly) {
            return (d: number) =>
                DateUtil.displayDateTimeAsZoneWithFormat(d, tz, dateDisplayFormat);
        } else if (valueType === PrecisionName.dateAndTime) {
            return (d: number) =>
                DateUtil.displayDateTimeAsZoneWithFormat(
                    d,
                    tz,
                    dateDisplayFormat + " " + getSearchTimeDisplayFormat(),
                );
        } else {
            // If this happens, it's a bug. Just return something reasonable:
            return (d: number) => "invalid";
        }
    }
    /**
     * This is duplicated on the backend as DateTime.RangeSearch.getTimezone().
     */
    getTimezone(value: DateTimeSearch) {
        if (value.timezone) {
            return value.timezone;
        } else if (value.type === PrecisionName.timeOnly) {
            return "UTC|UTC" as DateUtil.TimezoneO;
        } else {
            return "UTC" as DateUtil.TimezoneN;
        }
    }
}

export interface AddressListSearch {
    terms: AddressTerm[];
    comboType: ComboType;
    exclusive: boolean;
}

export type AutocompleteTerm = string | AddressTerm;

export class AddressTerm {
    static TERM_KIND_TO_ICON = {
        NAME: { class: "user-light-20", alt: "contact name" },
        EMAIL: { class: "mail-light-20", alt: "email address" },
        DOMAIN: { class: "at-light-20", alt: "domain" },
    };

    value: string;
    kind: AddressTermKind;
    associatedPrivateEmails: string[];
    associatedSharedEmails: string[];
    associatedNames: string[];

    static fromJson(obj: AddressTermJson): AddressTerm {
        return new AddressTerm(
            obj.value,
            obj.kind,
            obj.associatedPrivateEmails,
            obj.associatedSharedEmails,
            obj.associatedNames,
        );
    }

    constructor(
        value: string,
        kind?: AddressTermKind,
        associatedPrivateEmails?: string[],
        associatedSharedEmails?: string[],
        associatedNames?: string[],
    ) {
        this.value = value;
        this.kind = kind || AddressTermKind.TEXT;
        this.associatedPrivateEmails = associatedPrivateEmails || [];
        this.associatedSharedEmails = associatedSharedEmails || [];
        this.associatedNames = associatedNames || [];
    }

    icon() {
        const icon = AddressTerm.TERM_KIND_TO_ICON[this.kind];
        return icon && new Icon(`${icon.class} term-kind-icon`, { alt: icon.alt });
    }

    /**
     * Up to two rows of associated emails which will appear as "subscript" rows after the name term
     * they are associated with.
     */
    associatedRows(): string[] {
        const emails = [...this.associatedPrivateEmails, ...this.associatedSharedEmails];
        if (!emails) {
            return [];
        }

        const emailAppendedTo = (row, i) => row + emails[i] + (i < emails.length - 1 ? ", " : "");

        const result = [];
        let emailIdx = 0;
        for (let rowIdx = 0; rowIdx < 2; rowIdx++) {
            if (emailIdx === emails.length) {
                break;
            }

            // 2nd row slightly shorter to allow for potential " ..." if more emails than fit
            // in two rows.
            const maxRowLen = rowIdx === 0 ? 80 : 75;

            let row = "";

            // at least one email in each row, add as many as still fit
            do {
                row = emailAppendedTo(row, emailIdx++);
            } while (emailIdx < emails.length && emailAppendedTo(row, emailIdx).length < maxRowLen);

            // second (last) row - and yet there are more emails, end with "..."
            if (rowIdx === 1 && emailIdx < emails.length) {
                row += " ...";
            }
            result.push(row);
        }
        return result;
    }

    toString() {
        return this.value;
    }

    displayValue() {
        return this.value;
    }

    /**
     * We don't have exact or even close to exact hit count for name terms, since they aggregate
     * the results of all the emails.
     * However if the top autocomplete results include any of the associated emails we sum those up
     * and choose the higher of this and the name term count (which is the count of appearances
     * of the name itself.)
     * But that's just a lower bound (More or less. Could be cases where it's wrong, if multiple
     * emails of the same name appear in a single doc. But I think we can live with that slight
     * possibility.)
     */
    hitsIsLowerBound() {
        return this.kind === AddressTermKind.NAME;
    }

    /**
     * Hide the associated emails field so it is not part of the EQL. The server finds the (most
     * up to date) associated emails when it searches for a name term, so it's not needed in the
     * EQL.
     */
    toEqlValue() {
        return this.kind === AddressTermKind.NAME ? new AddressTerm(this.value, this.kind) : this;
    }
}

const COMBO_TYPE_DISPLAY = {
    [ComboType.ALL]: "All of",
    [ComboType.ANY]: "Any of",
    [ComboType.NOT_ANY]: "Exclude",
};

export function comboTypeDisplay(comboType: ComboType): string {
    return COMBO_TYPE_DISPLAY[comboType];
}

class AddressListSearchType extends Type implements EditableType {
    override isValidValue(search: AddressListSearch | string): boolean {
        if (!search) {
            return false;
        }

        // This is for stuff like import search term reports.
        if (typeof search === "string") {
            return true;
        }

        if (!search.comboType) {
            return false;
        }
        return search.terms && search.terms.length > 0;
    }

    constructValueWidget(): never {
        // This method isn't called. This is the "range case" but AddressSearchWidget is an
        // "exact" search widget, not range. (see AddressListType#metadataTermWidget)
        throw Error("This shouldn't happen. Use AddressSearchWidget instead.");
    }

    override displayValue(value: AddressListSearch) {
        const comboType = value.comboType;
        const nothingElse = value.exclusive ? " (and nothing else)" : "";

        if (value.terms.length === 1) {
            const prefix =
                comboType === ComboType.NOT_ANY ? `${comboTypeDisplay(comboType)}: ` : "";
            return prefix + value.terms[0].displayValue() + nothingElse;
        }
        const prefix = `${comboTypeDisplay(comboType)}${nothingElse}: `;
        return prefix + value.terms.map((term) => term.displayValue()).join(", ");
    }

    /**
     * This is needed only since it converts the terms from AddressTerm (which is a class)
     * to simple JSON.
     */
    override toJsonValue(value: AddressListSearch): any {
        return {
            comboType: value.comboType,
            exclusive: value.exclusive,
            terms: value.terms.map((t) => ({ value: t.value, kind: t.kind })),
        };
    }

    /**
     * This is needed mainly since it converts the terms from json to AddressTerm instances.
     * But also see comment about the String case.
     */
    override fromJsonValue(value): AddressListSearch {
        // The value is string in legacy searches when doing "refine".
        // It's also the case of editing a non-exact term created by import in search term report.
        // It is handled in the back-end in AddressListType#searchFromJson.
        if (Is.string(value)) {
            return {
                comboType: ComboType.ANY,
                exclusive: false,
                terms: [new AddressTerm(value, AddressTermKind.TEXT)],
            };
        }
        return {
            comboType: value.comboType,
            exclusive: value.exclusive,
            terms: value.terms.map((t) => new AddressTerm(t.value, t.kind)),
        };
    }
}

export class DateRangeWidget extends RangeWidget<DateTime, UI_DateBox, ConstrainedBox.Params> {
    static defaultWidth = "112px";
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    override type = DATE_TIME;
    private timezone: DateUtil.TimezoneN;
    buildWidget(params: ConstrainedBox.Params) {
        return new UI_DateBox(params);
    }

    constructor(params: RangeWidget.Params<ConstrainedBox.Params> = {}) {
        super(Object.assign({ width: DateRangeWidget.defaultWidth }, params));
        this.timezone = params.timezone || ("UTC" as DateUtil.TimezoneN);
    }
    override getValue(): DateTimeSearch {
        return { ...super.getValue(), type: PrecisionName.dateOnly, timezone: this.timezone };
    }
    isEmpty() {
        return this.begin.getDate() === null && this.end.getDate() === null;
    }
}

interface BoundedDateBoxParams extends ConstrainedBox.Params {
    min: SelfImport.DateTime;
    max: SelfImport.DateTime;
}

/**
 * A BoundedDateRangeWidget is a DateRangeWidget whose dateboxes are restricted to some
 * set of bounds.
 */
export class BoundedDateRangeWidget extends DateRangeWidget {
    constructor(params: RangeWidget.Params<BoundedDateBoxParams> = {}) {
        super(params);
    }
    override buildWidget(params: BoundedDateBoxParams) {
        const dateBox = new UI_DateBox(params);
        dateBox.setMin(params.min);
        dateBox.setMax(params.max);
        return dateBox;
    }
    setBounds(min: SelfImport.DateTime, max: SelfImport.DateTime) {
        this.begin.setMin(min);
        this.begin.setMax(max);
        this.end.setMin(min);
        this.end.setMax(max);
    }
}

export class LocalDateRangeWidget extends DateRangeWidget {
    override buildWidget(params: ConstrainedBox.Params) {
        return new UI_LocalDateBox(params);
    }
    override getValue(): DateTimeSearch {
        return {
            ...super.getValue(),
            type: PrecisionName.dateOnly,
            timezone: moment.tz.guess() as DateUtil.TimezoneN,
        };
    }
}

export interface RelativeDate {
    ago: number;
}

// We could beef this up with a real widget, stealing from EqlBuilder.WhatWhoWhen, but that seems
// like overkill for now.
class RelativeDateType extends Type {
    override isValidValue(value: RelativeDate) {
        return Is.object(value) && value.ago >= 0;
    }
    override displayValue(value: RelativeDate) {
        return Duration.ago(Date.now() - value.ago);
    }
}

export class BatesNumberType extends Type {
    override isSortable = true;

    getValueWidget() {
        return new Bates.NumberWidget();
    }

    override displayValue(val: Bates.Number) {
        return val.toString();
    }

    override toJsonValue(val: Bates.Number) {
        return val.toJSON();
    }

    override fromJsonValue(val: string) {
        // For frontend API purposes, we only support val as a string, but old searches stored it as
        // a number. We harmlessly convert it to ensure backwards-compatibility.
        return Bates.Number.fromString(String(val));
    }
}

export interface BatesRangeJson {
    begin?: string;
    end?: string;
}

export class BatesNumberRangeType extends Type {
    override displayValue(range: Bates.NumberRange) {
        if (range.begin && range.end) {
            const cmp = range.begin.compare(range.end);
            if (cmp === 0) {
                return range.begin.toString();
            } else if (cmp < 0) {
                return `${range.begin}-${range.end}`;
            }
        } else if (range.begin) {
            return `≥ ${range.begin}`;
        } else if (range.end) {
            return `≤ ${range.end}`;
        }
        return "[Invalid range]";
    }

    override toJsonValue(v: Bates.NumberRange) {
        return {
            begin: v.begin && v.begin.toJSON(),
            end: v.end && v.end.toJSON(),
        };
    }

    override fromJsonValue(v: BatesRangeJson) {
        // For frontend API purposes, we only support begin and end as strings, but old searches
        // stored them as numbers. We harmlessly convert them to ensure backwards-compatibility.
        return {
            begin: v.begin && Bates.Number.fromString(String(v.begin)),
            end: v.end && Bates.Number.fromString(String(v.end)),
        };
    }

    override isValidValue(range: Bates.NumberRange) {
        if (!range) {
            return false;
        }
        if (range.begin && range.end) {
            return (
                range.begin.isRolling() === range.end.isRolling()
                && range.begin.compare(range.end) <= 0
            );
        }
        return !!(range.begin || range.end);
    }
}

export interface BatesJson {
    prefix: string;
    numberDisplay: string;
    suffix?: string;
    page?: Bates.PageJson;
}

export class BatesType extends ValidatedFieldType {
    appendExactString = false;
    override showExactness = false;
    override isSortable = true;
    override displayInvalidValues = true;

    constructValueWidget(): Widget.WithSettableValue {
        const validated = new BatesWidget();
        this.forms = [validated];
        return validated;
    }

    override termRangeWidget(term: MetadataTerm): Widget.WithSettableValue {
        // Bates freeform codes are not currently implemented, so term should always be a MetadataTerm
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return BATES_SEARCH.constructValueWidget(term);
    }

    override searchFromExactValue(
        value: string | BatesJson | Bates,
        type?: FieldType,
    ): SearchWithExactness<BatesSearch> | SearchWithExactness<string> {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const textType = TEXT;

        // For legacy values that haven't been migrated from TextType.
        if (Is.string(value)) {
            return textType.searchFromExactValue(value);
        }

        if (type === textType) {
            // SPECIAL CASE: Called from an alias field (which has to be of type TEXT)
            return this.textSearchFromExactValue(value);
        }
        return this.batesSearchFromExactValue(value);
    }

    private textSearchFromExactValue(value: BatesJson | Bates): SearchWithExactness<string> {
        let rawValue;
        if ("numberDisplay" in value) {
            // BatesJson case
            rawValue = this.rawValue(value);
        } else {
            // Bates case
            rawValue = Bates.display(value);
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return TEXT.searchFromExactValue(rawValue);
    }

    private batesSearchFromExactValue(value: BatesJson | Bates): SearchWithExactness<BatesSearch> {
        let number: Bates.Number;
        if ("numberDisplay" in value) {
            // BatesJson case
            number = Bates.Number.fromString(value.numberDisplay);
        } else {
            // Bates case
            number = value.number;
        }

        const searchValue: Bates.BatesSearch = {
            prefix: value.prefix,
            ranges: [{ begin: number, end: number }],
        };
        return { exact: false, searchValue };
    }

    searchType(): EditableType {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return BATES_SEARCH;
    }

    override displayValue(value: string | BatesJson): string {
        // For legacy values that haven't been migrated from TextType.
        if (Is.string(value)) {
            return value;
        }
        return Bates.display(this.fromJsonValue(value) as Bates);
    }

    override fromJsonValue(v: string | BatesJson): string | Bates {
        // For legacy values that haven't been migrated from TextType.
        if (Is.string(v)) {
            return v;
        }
        return {
            prefix: v.prefix,
            number: Bates.Number.fromString(v.numberDisplay),
            suffix: v.suffix,
            page: v.page && Bates.Page.fromJson(v.page),
        };
    }

    override rawValue(value: any): string {
        return this.displayValue(value);
    }

    override isValidEditValue(value: any): boolean {
        return Bates.parseBates(value, true) != null;
    }

    override viewableAs(other: FieldType): boolean {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return other === BATES || other === TEXT;
    }
}

export interface BatesSearchJson {
    prefix: string;
    ranges: BatesRangeJson[];
}

export class BatesSearchType extends Type implements EditableType {
    override displayValue(value: Bates.BatesSearch): string {
        if (value === null) {
            return null;
        }

        const prefix = value.prefix || "";
        if (prefix === NO_VALUE) {
            return NO_VALUE;
        }

        const ranges = value.ranges || [];
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const rangeString = ranges.map((n) => BATES_NUMBER_RANGE.displayValue(n)).join(", ");

        if (!prefix && !rangeString) {
            return null;
        }
        if (!prefix) {
            return rangeString;
        }
        if (!rangeString) {
            return prefix;
        }
        return `${prefix} ${rangeString}`;
    }

    constructValueWidget(term?: MetadataTerm): Widget.WithSettableValue {
        return term ? term.createBatesRangesWidget() : new Bates.BatesRangesWidgetWithDropdown();
    }

    override fromJsonValue(v: BatesSearchJson): string | BatesSearch {
        // For legacy searches that haven't been migrated from TextType.
        if (Is.string(v)) {
            return v;
        }
        return {
            prefix: v.prefix,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            ranges: v.ranges.map((r) => BATES_NUMBER_RANGE.fromJsonValue(r)),
        };
    }

    override isValidValue(value: BatesSearch): boolean {
        if (value === null) {
            return false;
        }
        // For legacy searches that haven't been migrated from TextType.
        if (Is.string(value)) {
            return true;
        }
        const prefix = value.prefix;
        const ranges = value.ranges;
        if (!ranges) {
            return !!prefix;
        }
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        return ranges.every((range) => BATES_NUMBER_RANGE.isValidValue(range));
    }
}

/**
 * Validated widget for inputting Bates. Located here instead of in Bates.ts to avoid circular
 * dependency.
 *
 * TODO: modify widget (and BatesValue.java) to have separate textboxes for prefix and number
 */
export class BatesWidget extends UI_Validated.Text implements Widget.WithSettableValue {
    constructor(params?: UI_Validated.ValidatedParams) {
        super(
            Object.assign(
                {
                    name: "Bates",
                    // eslint-disable-next-line @typescript-eslint/no-use-before-define
                    validator: (value: string) => BATES.isValidEditValue(value),
                },
                params,
            ),
        );
    }

    override setValue(val: any) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const batesStr = val ? BATES.displayValue(val) : "";
        super.setValue(batesStr);
    }
}

// Types that take objects as their first parameter are subclassed from Type, rather than instances
// of it. Thus, the base Type constructor that mixes in the first argument should not be called.
class Parameterized extends Type {
    constructor(
        name: string,
        public type: Type,
    ) {
        super(name);
    }
    override equals(other: Parameterized) {
        return this.name === other.name && this.type.equals(other.type);
    }
}

// Type.Optional values can either be the type specified or null.
export class Optional extends Parameterized {
    constructor(type: Type) {
        super("Optional", type);
        this.isSortable = type.isSortable;
    }
    override displayValue(v: any) {
        // not sure what to display in case of null
        return v == null ? "none" : this.type.displayValue(v);
    }
    override toJsonValue(v: any) {
        return v == null ? null : this.type.toJsonValue(v);
    }
    override fromJsonValue(v: any) {
        return v == null ? null : this.type.fromJsonValue(v);
    }
    override isValidValue(v: any) {
        return v == null || this.type.isValidValue(v);
    }
}

// A special type annotation used by the type checker on polymorphic types to
// ignore certain values until they have been resolved.
export class Ignorable {}

export const NUMBER = new NumberType("Number"); // an unbounded instance of NumberType
export const NON_NEGATIVE_NUMBER = new NumberType("NonNegativeNumber", 0);
export const PATH_TEXT = new PathTextType("PathText");
export const BOOLEAN = new BooleanType("Boolean");
export const NULL = new NullType("Null");
export const TEXT = new TextType("Text");
export const MD5 = new TextType("MD5");
export const SHA1 = new TextType("SHA1");
export const DATE_TIME = new DateTimeType("DateTime");
export const DATE_TIME_SEARCH = new DateTimeSearchType("DateTimeSearch");
export const RELATIVE_DATE = new RelativeDateType("RelativeDate");
export const IGNORED_TYPE_SPECIFIER = new Ignorable();
export const ADDRESS_LIST = new AddressListType("AddressList");
export const ADDRESS_FROM = new AddressFromType("AddressFrom");
export const ADDRESS_LIST_SEARCH = new AddressListSearchType("AddressListSearch");
export const BATES_NUMBER = new BatesNumberType("BatesNumber");
export const BATES_NUMBER_RANGE = new BatesNumberRangeType("BatesNumberRange");
export const BATES = new BatesType("Bates");
export const BATES_SEARCH = new BatesSearchType("BatesSearch");

/**
 * Map from the names we used so far (class name like CamelCase) to the consts that are now named
 * like constants should be named. Ideally we should just have the const names, but it's in the DB
 * and so on, so for now this will do.
 */
export const TYPE_BY_NAME = {};
Object.values(SelfImport).forEach((member) => {
    if (member instanceof Type) {
        TYPE_BY_NAME[member.name] = member;
    }
});

// In a polymorphic type, for a field whose type is dependent on another field,
// this value should be used instead of an instantiated type.
export class PolymorphicOn<T> extends Ignorable {
    constructor(
        public key: string,
        public typeSpecClass: new (...args: any[]) => T,
        public getType: (spec: T) => Type,
    ) {
        super();
    }
}

type TypeOrPolymorphic = Type | PolymorphicOn<unknown>;

/* A polymorphic type is an aggregate type in which one field's type depends on
 * the value of another field. (A better name for this might be DependentType.)
 * The dependent field must be Type.PolymorphicOn(field), where field is the
 * string name of the field that specifies its type. That field's ultimate value
 * must be an instance of typeSpecClass. The machinery below uses this information
 * to first evaluate the TypeSpecifier's field to obtain a value, then uses the
 * type provided by that value to assign a type to the PolymorphicOn field and
 * typecheck it. For example,
 * Property.metadata = new Property({
 *   args: new Type.Polymorphic({
 *       field: new Type.Object(Metadata.Field),
 *       value: new Type.PolymorphicOn('field', Metadata.Field, mf => mf.type)
 *   })
 * });
 * Here, the type of value can only be determined once we have field's actual objects.
 */
export class Polymorphic extends Type {
    constructor(public typeStructure: { [key: string]: TypeOrPolymorphic }) {
        super("Polymorphic");
    }
    override displayValue(v: any) {
        return this.traverse(v, "displayValue");
    }
    override toJsonValue(v: any) {
        return this.traverse(v, "toJsonValue");
    }
    override fromJsonValue(v: any) {
        // first we convert the non-PolymorphicOn types
        const obj = traverse(this.typeStructure, v, "fromJsonValue");
        // then we figure out the types of the polymorphic fields, and ignore the ones we've already
        // converted
        return this.traverse(obj, "fromJsonValue", true);
    }
    override isValidValue(v: any) {
        const res = this.traverse(v, "isValidValue");
        if (!res) {
            return false;
        }
        if (Is.boolean(res)) {
            return res;
        }
        if (Is.array(res)) {
            return res.every((v) => !!v);
        }
        return Object.values(res).every((v) => !!v);
    }
    override equals(other: Polymorphic) {
        return this === other; // for now
    }
    private traverse(value: any, f: string, setSpecifiersToIgnore = false) {
        // Find all PolymorphicOn fields and attempt to resolve them.
        const resolved: any = {};
        Object.entries(this.typeStructure).forEach(([key, type]) => {
            if (type instanceof PolymorphicOn) {
                const toWhat = value[type.key];
                if (toWhat instanceof type.typeSpecClass) {
                    if (setSpecifiersToIgnore) {
                        resolved[type.key] = IGNORED_TYPE_SPECIFIER;
                    }
                    resolved[key] = type.getType(toWhat);
                } else {
                    throw "PolymorphicOn field does not point to TypeSpecifier";
                }
            } else {
                if (!resolved[key]) {
                    // might have already been set to Ignore
                    resolved[key] = type;
                }
            }
        });
        return traverse(resolved, value, f);
    }
}

export class List extends Parameterized {
    override isSortable = false;
    constructor(type: Type) {
        super("List", type);
    }
    override toJsonValue(values: any[]) {
        return values.map((v) => this.type.toJsonValue(v));
    }
    override fromJsonValue(values: any[]) {
        return values.map((v) => this.type.fromJsonValue(v));
    }
    override isValidValue(values: any[]) {
        return Is.array(values) && values.every((v) => this.type.isValidValue(v));
    }
    override displayValue(values: any[]) {
        return values.map((v) => this.type.displayValue(v)).join(", ");
    }
}

class Obj<O extends Base.Object> extends Type implements EditableType {
    constructor(
        public objectClass: { prototype: O },
        public isValidElement = (obj: O) => true,
    ) {
        super("Object");
    }

    override equals(other: Type): boolean {
        if (!(other instanceof Obj)) {
            return false;
        }
        return this.name === other.name && this.objectClass === other.objectClass;
    }

    getElements(): O[] {
        return Base.get(this.objectClass).filter(this.isValidElement);
    }

    getElementsSorted(): O[] {
        return Arr.sort(this.getElements());
    }

    override toJsonValue(value: O): string | number {
        return value.id;
    }

    override fromJsonValue(value: any): O {
        return Base.get(this.objectClass, value);
    }

    override displayValue(value: O): string {
        if (!value) {
            // If value is `undefined` or otherwise not the expected type, log an error and return
            // "" as a fallback. It shouldn't be necessary to do this in a world where our typing is
            // correct, but for now just add logging to investigate whether we're relying on this
            // behavior from this very old code.
            Bugsnag.notify(
                Error(`Invalid value ${value}; expected ${this.objectClass.prototype.className}`),
            );
            return "";
        }
        return value.display();
    }

    constructValueWidget(params?: BaseSingleSelect.Params<O>): SingleSelect<O> {
        const widget = new SingleSelect<O>(
            Object.assign(
                {
                    elements: this.getElementsSorted(),
                    headers: false,
                    selectOnSame: true,
                    popup: "after",
                    onSelect: () => widget.blur(),
                    textBoxAriaLabel: "Enter portion of name",
                },
                params,
            ),
        );
        return widget;
    }

    override isValidValue(value: O): boolean {
        return (
            value
            // The below cast to Function is used to check something is an instance of a class
            // (and not calling it), so it's okay.
            // eslint-disable-next-line @typescript-eslint/ban-types
            && value instanceof (this.objectClass as Function)
            && Base.get(this.objectClass, value.id)
            && this.isValidElement(value)
        );
    }
}
export { Obj as Object }; // can't use the Object name locally due to clash with global Object

function format(name: string, formats: string[], description = "") {
    return {
        name: name,
        formats: formats,
        description: description,
    };
}

const DT_FORMATS = [
    format("Year", ["y"]),
    format("Month", ["M", "MMM"], "(1-12), (Mar; March), respectively"),
    format("Day", ["d"], "(1-31)"),
    format("Hour", ["h", "H", "k", "K"], "(1-12), (0-23), (1-24), (0-11), respectively"),
    format("Minute", ["m"], "(0-59)"),
    format("Second", ["s"], "(0-59)"),
    format("Millisecond", ["S"], "(0-999)"),
    format("AM/PM", ["a"], "in various forms"),
    format("General time zone", ["z"], "(Pacific Standard Time; PST; GMT-08:00; -800; -0800)"),
    format("ISO 8601 time zone", ["X"], "(Z; -08:00; -800; -08)"),
];

const DT_DESC =
    "Non-alphabetic characters and any characters "
    + "enclosed in single quotes are interpreted literally. The alphabetic "
    + "characters below have the indicated meanings.";

export const requiresFormat: { [type: string]: () => HTMLElement } = {
    DateTime: function () {
        return Dom.div(
            DT_DESC,
            Dom.ul(
                DT_FORMATS.map((spec) => {
                    return Dom.li(
                        Dom.join(
                            ", ",
                            spec.formats.map((fmt) => Dom.b(fmt)),
                        ),
                        " ",
                        E.NDASH,
                        " ",
                        spec.name,
                        " ",
                        spec.description,
                    );
                }),
            ),
            Dom.p(
                "For more information, please refer to ",
                Dom.b(Dom.i("Date and Time Patterns")),
                " in ",
                Dom.a(
                    {
                        href: "https://docs.oracle.com/javase/8/docs/api/java/text/SimpleDateFormat.html",
                        target: "_blank",
                        rel: "noopener noreferrer",
                    },
                    "Java's date format documentation",
                ),
                ".",
            ),
        );
    },
};

export type TypeStructure = Type | { [key: string]: Type };
type PolymorphicTypeStructure = TypeOrPolymorphic | { [key: string]: TypeOrPolymorphic };

// Navigates a value corresponding to a specified Type structure invoking
// funcName on each appropriate value-type pair.
// For example, if typeStructure is
// { foo: Type.Number, bar: Type.Text },
// value is
// { foo: 10, bar: "baz" },
// and funcName is isValidValue, Type.traverse returns:
// { foo: Type.Number.isValidValue(10), bar: Type.Text.isValidValue("baz") }.
export function traverse(
    typeStructure: PolymorphicTypeStructure,
    value: any,
    funcName: string,
): any {
    if (!typeStructure) {
        return null;
    } else if (typeStructure instanceof Type) {
        return typeStructure[funcName](value);
    } else if (typeStructure instanceof Ignorable) {
        return value;
    } else {
        const newMap: { [p: string]: TypeOrPolymorphic } = {};
        Object.entries(typeStructure).forEach(([key, type]) => {
            newMap[key] = traverse(type, value[key], funcName);
        });
        return newMap;
    }
}
